import React from 'react'
import MCHLogo from '../atoms/MyCampusHubsLogo'

const Project = () => {
    return (
        <div id='project-container'>
            <div id='project-logo'>
                <MCHLogo />
            </div>
            <p>A platform that revolutionizes the campus experience by connecting college students with each other socially, with professors and classmates academically, and with the community surrounding their campus. </p>
            <a href='https://www.mycampushubs.com' target='_blank'>Visit the site</a>
        </div>
    )
}

export default Project