import { React } from 'react'
import HeaderLogo from '../atoms/HeaderLogo';

const MainNav = (props) => {
  return (
    <div id='header'>
      <div id='header-container'>
        <div id='header-logo'>
            <HeaderLogo />
        </div>
        <div id='header-nav'>
          <ul>
            <li className={(props.pageSelected === "about") ? 'active' : null} onClick={() => props.scrollIntoView("about")}>About</li>
            <li className={(props.pageSelected === "projects") ? 'active' : null} onClick={() => props.scrollIntoView("projects")}>Projects</li>
            <li className={(props.pageSelected === "contact") ? 'active' : null} onClick={() => props.scrollIntoView("contact")}>Contact Us</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MainNav