import React from 'react'

const Footer = () => {
  return (
    <div id='footer-container'>
        <p>© 2024 SurgePoint Software LLC</p>
    </div>
  )
}

export default Footer