const { GlobalURL_FLAG } = require('./GlobalFlagValue')

function getGlobalURL() {

  // ############################ backend URL start #############################################

  // ! MAKE THE CHANGE BELOW HERE!!!!!!!!!!
  //const GlobalURL_FLAG = 0; // THIS IS NOW IN ITS OWN MODULE. OPEN GlobalFlagValue.js and change there. Only one change needed now.
  // ! MAKE THE CHANGE ABOVE HERE!!!!!!!!!!

    let url = ""

    if (GlobalURL_FLAG === 1) { // ! DON'T CHANGE THIS. SHOULD READ 1
        url = "http://localhost:3001/api/";
    } else {
        url = "https://www.surgepointsoftware.com/api/";
    }

    return url;
}

export default getGlobalURL;
