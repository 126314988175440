import React from 'react'

const AboutSlide = ({ slideRefs }) => {
  return (
    <div id='about-slide' ref={el => slideRefs.current = { ...slideRefs.current, about: el}} className='slide-container'>
      <div id='about'>
        <h1>Where Innovation Meets Momentum</h1>
        <p>
Welcome to SurgePoint Software, where innovation drives our passion to empower businesses in the digital age. Specializing in custom software solutions, we're dedicated to turning your vision into reality. Let's work together to propel your business forward.</p>
      </div>
    </div>
  )
}

export default AboutSlide