import {React, useRef, useState} from 'react'
import axios from 'axios';
// import emailjs from '@emailjs/browser'
import getGlobalURL from "../../assets/scripts/Global";
const { GlobalURL_Flag } = require('../../assets/scripts/GlobalFlagValue')

const ContactSlide = ({ slideRefs }) => {
    const url = getGlobalURL();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [body, setBody] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        console.log(`Hello ${name}. Your email is ${email}. The body of your message is: \n${body}`);

        axios.patch(`${url}users/contactUs`, {
            username: name,
            email: email,
            body: body
        }).then((response) => {
            if(response.status === 200) {
                alert('email sent!');
            }
            setName("");
            setEmail("");
            setBody("");
        }).catch((error) => {
            alert(error.response.data.err)
        })
    };

    return (
        <div id='contact-slide' className='slide-container' ref={el => slideRefs.current = { ...slideRefs.current, contact: el}}>
            <div id='contact'>
                {/* Map through projects list here when ready */}
                <div id='contact-left-column'>
                    <h1>CONTACT US</h1>
                    <p>Get in touch with us by filling out the form. We're here to answer any questions you have and discuss how our solutions can benefit your business. Your inquiry is important to us, and we'll get back to you as soon as possible. Thank you for considering SurgePoint Software as your software partner.</p>
                </div>
                <form onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" onChange={(e) => setName(e.target.value)} name="name" />
                    <label>Email</label>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} name="email" />
                    <label>Message</label>
                    <textarea onChange={(e) => setBody(e.target.value)} name="body" />
                    <input id='submit-btn' type="submit" value="Send" />
                </form>
            </div>
        </div>
    )
}

export default ContactSlide