import React from 'react'
import Project from '../molecules/Project'

const ProjectSlide = ({ slideRefs }) => {
    return (
        <div id='projects-slide' ref={el => slideRefs.current = { ...slideRefs.current, projects: el}} className='slide-container'>
            <div id='projects'>
                {/* Map through projects list here when ready */}
                <Project />
            </div>
        </div>
    )
}

export default ProjectSlide