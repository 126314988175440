import { React, useState, useRef, Fragment } from 'react';
import MainNav from './components/navigation/MainNav';
import Footer from './components/molecules/Footer';
import AboutSlide from './components/slides/AboutSlide';
import ProjectSlide from './components/slides/ProjectSlide';
import ContactSlide from './components/slides/ContactSlide';

const App = () => {
  const [pageSelected, setPageSelected] = useState('about')
  const slideRefs = useRef({});

  const scrollIntoView = (type) => {
    setPageSelected(type);
    slideRefs.current[type].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Fragment>
      <div id='main-container'>
        <MainNav scrollIntoView={scrollIntoView} pageSelected={pageSelected} />
        <AboutSlide slideRefs={slideRefs}/> 
        <ProjectSlide slideRefs={slideRefs}/>
        <ContactSlide slideRefs={slideRefs} />
      </div>
      <Footer />
    </Fragment>
  );
}

export default App;
